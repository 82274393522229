@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=block');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-display: block;
    font-family: 'eina01';
    font-style: normal;
    font-weight: 100 300;
    src: url('/public/fonts/eina01/Eina01-Light.ttf') format('truetype');
  }
  @font-face {
    font-display: block;
    font-family: 'eina01';
    font-style: normal;
    font-weight: 400;
    src: url('/public/fonts/eina01/Eina01-Regular.ttf') format('truetype');
  }

  @font-face {
    font-display: block;
    font-family: 'eina01';
    font-style: normal;
    font-weight: 600;
    src: url('/public/fonts/eina01/Eina01-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-display: block;
    font-family: 'eina01';
    font-style: normal;
    font-weight: bold;
    src: url('/public/fonts/eina01/Eina01-Bold.ttf') format('truetype');
  }

  @font-face {
    font-display: block;
    font-family: 'P22 Mackinac';
    font-style: normal;
    font-weight: bold;
    src: url('/public/fonts/P22Mackinac/P22Mackinac-Medium_6.otf')
      format('opentype');
  }

  .solicitors-bg {
    background: linear-gradient(
      0deg,
      rgba(127, 194, 194, 0.5) 0%,
      rgba(254, 254, 254, 0) 60%
    );
    backdrop-filter: blur(100px);
  }
}
